import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home";

import InConf from "../pages/InConference/InConf";
import Admin from "../Layouts/Admin";
import Guest from "../Layouts/Guest";
import Logout from "./Logout";
import Scan from "../pages/Scan/Scan";
import Result from "../pages/Scan/Result";
const Router = createBrowserRouter([
    {
        path:"/", 
        element: <Admin /> ,
        children:[
            {
                path:"/",
                element: <Home/>,
            }, {
                path:"/inconfereance" ,
                element:<InConf/>

            }, {
                path:"/scan" ,
                element:<Scan/>

            },{
            path: "/show/:id" , 
            element : <Result/> }
            
        ]
        },
        {
            path:"/login" ,
            element: <Guest/> 

        }
        ,{
            path:"logout",
            element:<Logout/>
        }
]);
export default Router ; 