import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight, FaChevronLeft } from 'react-icons/fa';
import { CiMenuKebab } from "react-icons/ci";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isCurrentLocation = (pathname) => {
    return location.pathname === pathname;
  };

  useEffect(() => {
    setIsMenuOpen(false);
    
    
    
    window.scrollTo(0, 0);

  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { path: '/', name: 'Home' },
    { path: '/inconfereance', name: 'inconfereance' },
    { path: '/scan', name: 'scan' },

    { path: '/logout', name: 'logout' },

  ];

  return (
    <nav className="bg-slate-800 sticky top-0 z-50 left-0 w-full shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-13">
          <div className="flex items-center">
           
            <Link to="/"> 
            <h1 className="text-white font-bold text-md lg:text-2xl  md:text-xl m-2 md:m-1 ">
             Al-Aliaa | Conference Monitor
            </h1></Link>

            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.path}
                    className={`text-slate-100 hover:bg-slate-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${
                      isCurrentLocation(item.path) ? 'bg-slate-700' : ''
                    }`}
                  >
                    {item.name}
                  </Link>
                ))}
                
              </div>
            
            </div>
           
          </div>

          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="bg-slate-800 inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen ? 'true' : 'false'}
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <FaChevronCircleLeft className="h-6 w-6" />
              ) : (
                <CiMenuKebab  className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } shadow-lg transform md:hidden fixed top-0 right-0 w-64 h-full bg-slate-800 transition-transform duration-300 ease-in-out`}
        id="mobile-menu"
      >
        <div className="px-4 py-5">
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-white font-bold text-2xl">Menu</h1>
            <button
              onClick={toggleMenu}
              className="text-slate-300 hover:text-white"
            >
              {isMenuOpen ? (
                <FaChevronCircleRight className="h-6 w-6" />
              ) : (
                <FaChevronLeft className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className="space-y-4">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={`text-slate-300 hover:bg-slate-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium ${
                  isCurrentLocation(item.path) ? 'bg-slate-700' : ''
                }`}
              >
                {item.name}
              </Link>
            ))}
            
          </div>
         
        </div>
      </div>
    </nav>
  );
};

export default Nav;