import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AxiosClient from '../../assets/AxiosClient';
import { useStateContext } from '../../Contexts/ContextProvider';

const InConf = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [searchName, setSearchName] = useState('');
    const [searchPosition, setSearchPosition] = useState('');
    const { API } = useStateContext();

    const fetchUsersByAttendance = async () => {
        setLoading(true);
        try {
            const response = await AxiosClient.get(`/getUsersByAttendanceOnDay/2024-04-27?page=${page}&limit=25`);
            setUsers(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsersByAttendance();
    }, [page]); // Reload users when page changes

    const handlePhoneCall = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`, '_blank'); // Open the phone dialer with the phone number
    };

    const handleSearch = () => {
        // Filter users locally based on searchName and searchPosition
        const filteredUsers = users.filter((user) => {
            const matchesName = user.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
                                user.last_name.toLowerCase().includes(searchName.toLowerCase());
            const matchesPosition = user.position.toLowerCase().includes(searchPosition.toLowerCase());
            return matchesName && matchesPosition;
        });

        // Update users state with filtered results
        setUsers(filteredUsers);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const resetFiltersAndFetch = () => {
        // Reset search inputs and fetch users with current pagination settings
        setSearchName('');
        setSearchPosition('');
        fetchUsersByAttendance();
    };

    if (loading) {
        return <div className="p-4">Loading...</div>;
    }

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Users Attending on 2024-04-27</h2>
            <div className="mb-4 flex ">
            
                
              
            </div>
          
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    {/* Table header ... */}
                    <tbody className="bg-white divide-y divide-gray-200">
                    {users.map(user => (
                            <tr key={user.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <img
                                        src={`${API}${user.image}`}
                                        alt={user.name}
                                        className="w-8 h-8 rounded-full"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {user.first_name} {user.last_name}
                                </td>
                          
                                <td
                                    className="px-6 py-4 whitespace-nowrap text-blue-600 cursor-pointer"
                               
                                >
                                  <button   
                                    className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => handlePhoneCall(user.phone)} >
                                       Call </button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Pagination */}
                <div className="mt-4 flex justify-center">
                    {/* Example pagination buttons */}
                    <button
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1}
                        className="mr-2 bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded-md"
                    >
                        Prev
                    </button>
                    <button
                        onClick={() => handlePageChange(page + 1)}
                        className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded-md"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InConf;
