import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <div  className="w-full px-32 " >
        <img className="w-32 my-8" src="https://baghdad-iwc.com/logo.png" alt="logo" />

        </div>
       <h1 className="text-xl font-bold my-2 leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Conference Monitor Login              </h1>

      <div className="flex space-x-4">
        <Link
          to="/scan"
          className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md transition-colors duration-300"
        >
          Scan Code
        </Link>
        <Link
          to="/conference"
          className="bg-emerald-600 hover:bg-slate-700 text-white font-bold py-3 px-6 rounded-md transition-colors duration-300"
        >
          In Conference
        </Link>
      </div>
    </div>
  );
};

export default Home;
