import React from 'react';
import App from '../App';
import { Navigate } from 'react-router-dom';
import { useStateContext } from '../Contexts/ContextProvider';
import Notification from '../assets/Notification';
import { Fade } from 'react-awesome-reveal';

const Admin = () => {
const {token} = useStateContext() ; 
const {notifications} = useStateContext(); 

if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <App/>
      {
                notifications.map (
                    notifi=> 
                    
                  <div   key={notifi.id}><Fade triggerOnce>  <Notification notification = {notifi} />    </Fade>  </div>
                 
                )
            }
    </div>
  );
};

export default Admin;