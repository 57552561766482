import axios from "axios"

const AxiosClient = axios.create({
    baseURL: `https://api.baghdad-iwc.com` ,
}) ; 

AxiosClient.interceptors.request.use( (config)=>{
    const token = localStorage.getItem('Monitor_Token') ;  // Key-value 
    console.log(config) ; 
    config.headers.Authorization = `Bearer ${token}` ; 
    return config ;  
})
AxiosClient.interceptors.response.use( (res) =>{
return res ; 
} , 
(error )=>{ 
const {response } = error; 


if (response.status ==401) {
        localStorage.removeItem('Monitor_ACCESS_TOKEN') ; 
        

        
}
else console.log(response);
throw error ; 
 } )
export default AxiosClient ; 
