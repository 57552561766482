import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './auth/Login';
import { useStateContext } from '../Contexts/ContextProvider';

const Guest = () => {
    const {token } = useStateContext() ; 
if (token) {
    return <Navigate to="/" />;

  }
  return (
<section className="bg-gray-50 h-full">
<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" className="flex items-center mb-6 text-2xl  font-bold text-gray-900 ">
        <div  className="w-full px-32 " >
        <img className="w-32" src="https://baghdad-iwc.com/logo.png" alt="logo" />

        </div>
        
           
      </a>
    <Login/>

    </div>
    </section>
  );
};

export default Guest;