import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";

const QrReader = () => {
  const naviagte = useNavigate();

  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [scannedResult, setScannedResult] = useState("");
  const [qrOn, setQrOn] = useState(true);

  const onScanSuccess = (result) => {
    setScannedResult(result?.data);
    const lastParameter = extractLastParameter(result?.data);
    if (lastParameter) {
      naviagte(`/show/${lastParameter}`);
    }
  
  };
  const extractLastParameter = (url) => {
    if (!url) return null;

    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    const requestCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" , audio: false, }, // Specify 'environment' for back camera
        });

        if (videoEl.current) {
          videoEl.current.srcObject = stream;
          scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
            onDecodeError: onScanFail,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            overlay: qrBoxEl.current || undefined,
          });

          scanner.current.start().then(() => setQrOn(true));
        }
      } catch (err) {
        console.error("Camera access denied:", err);
        alert("Camera access denied. Please enable camera permissions.");
        setQrOn(false);
      }
    };

    requestCameraPermission();

    return () => {
      if (scanner.current) {
        scanner.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      alert("Error: Camera not accessible.");
    }
  }, [qrOn]);

  return (
    <div className="qr-reader">
      <video ref={videoEl} autoPlay={true} playsInline={true}></video>
      <div ref={qrBoxEl} className="qr-box"></div>
      {scannedResult && (
        <p
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 99999,
            color: "white",
          }}
        >
          Scanned Result: {scannedResult}
        </p>
      )}
    </div>
  );
};

export default QrReader;
