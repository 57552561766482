import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-slate-800">
      {/* Top Footer Section */}
      <div className="container mx-auto px-4 py-8">
        {/* Add your top footer content here */}
       

      </div>
      
      {/* Bottom Footer Section */}
      <div className="bg-slate-900 py-4">
        <div className="container mx-auto px-4">
          {/* Add your bottom footer content here */}
          <div className="flex justify-between items-center">
            <p className="text-white hover:text-blue-200"> By : <a href="mailto:ghayth.moustpha@gmail.com" target='_blank'> Eng. Ghayth Moustapha  | Al-Aliaa </a> </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
