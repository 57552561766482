import React, { useEffect } from 'react';
import { useStateContext } from '../Contexts/ContextProvider';

const Logout = () => {
 const {setToken} =useStateContext() ; 

  useEffect(() => {
    localStorage.removeItem('Monitor_ACCESS_TOKEN');

    setToken(null);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">Goodbye!</h1>
      <p className="text-lg">You have been logged out.</p>
    </div>
  );
};

export default Logout;