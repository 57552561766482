import {  createContext, useContext, useState } from "react";

const StateContext = createContext ({
    user : null , 
    token : null, 
    notifications : null , 
    API : "https://api.baghdad-iwc.com" , 
    SetNotification:()=>{},
    setUser : () =>{} , 
    setToken : ()=>{} 
})

export const ContextProvider = ({children})=>{

    const userinlocal = localStorage.getItem("Monitor")
const [user, _setUser] = useState( userinlocal ? JSON.parse(userinlocal) : {name : "username"} );
const [token , _setToken] = useState (localStorage.getItem('Monitor_Token')) ; 
const [API , setAPI] = useState('https://api.baghdad-iwc.com') ; 
const [notifications , SetNotifications] = useState ([]) ; 

const SetNotification  =(ms,ty ) =>{ 
    const not_id =notifications.length + 1  ; 
    const noti ={message : ms , type : ty , id : not_id} ; 
    SetNotifications ( old => [ ...old, noti]) ; 
    console.log(notifications) ;
    setTimeout(() =>{ 
        SetNotifications((notifications) =>
        notifications.filter((Notification) => Notification.id !== not_id))
    }, 4000
    );

}

const setToken = (_token)=>{
    _setToken(_token) ; 
    if (_token) {
        localStorage.setItem('Monitor_Token' , _token) ; 
    }else {
        localStorage.removeItem('Monitor_Token') ; 
    }
}
const setUser = (user)=> {
    _setUser (user) ; 
    if (user) {
    localStorage.setItem('Monitor', JSON.stringify(user));
    }else localStorage.removeItem("Monitor") ; 
}
    return (
        <StateContext.Provider value={ {
            user , 
            token, 
            setUser , 
            setToken , 
            API,
            notifications,
            SetNotification , 
         }} >
          {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);
