import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useStateContext } from '../../Contexts/ContextProvider';
import AxiosClient from '../../assets/AxiosClient';
import sucImg from './success.png';

const Result = () => {
  const { id } = useParams();
  const { API, SetNotification } = useStateContext();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isInvited, setIsInvited] = useState(false);
  const [DayId, setDayID] = useState(null);
  const [Registerd, isRegisterd] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosClient.get(`public/verification/${id}`);
        setUser(response.data.user);
        console.log(user);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const checkInvitation = () => {
      const today = new Date();
     // const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      const todayString = "2024-04-27"; 
      const invitedDay = user && user.dates.find(dateObj => dateObj.date === todayString);
      if (invitedDay) {
        setIsInvited(true);
        setDayID(invitedDay.id);
        console.log(invitedDay) ;
        //isRegisterd(invitedDay.)
      } else {
        setIsInvited(false);
        setDayID(null);
      }
    };

    if (user) {
      checkInvitation();
    }
  }, [user]);

  const handleMakeAttend = async () => {
    try {
      if (DayId) {
        await AxiosClient.put(`/attendances/${DayId}/mark-attended/1`);
        SetNotification("User has been attended to the Conference" , 'success');
      } else {
        console.error('No valid DayId found for attendance.');
      }
    } catch (error) {
      console.error('Error marking attendance:', error);
    }
  };

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="container mx-auto p-2">
      <nav className="flex items-center justify-between bg-white shadow-lg p-4">
        <div className="flex items-center">
          <img src="https://baghdad-iwc.com/logo.png" alt="Logo" className="w-10 mr-2" />
          <span className="font-bold text-sm">4th Baghdad International Water Conference</span>
        </div>
      </nav>

      <div className="bg-white rounded-lg shadow-lg py-8 px-2">
        <img src={`${API}${user.image}`} alt="Profile" className="h-40 mx-auto mb-4" />

        <div className="text-center mb-4">
          <h2 className="text-xl font-bold">{user.name}</h2>
          <p className="text-gray-700">
            <span className="font-bold">Position:</span> {user.position}
          </p>
          <p className="text-gray-700">
            <span className="font-bold">Organization:</span> {user.organization}
          </p>

          {isInvited ? (
            <div className="text-green-600 font-bold mx-auto">
              <p className="col-2">Invited for today</p>
              <img src={sucImg} className="h-5 mx-auto col-2" alt="Success" />
            </div>
          ) : (
            <div className="text-red-600 font-bold mx-auto">
              <p className="col-2">Not Invited for Today</p>
            </div>
          )}
                  {user.dates[0].attended && <h1 className='text-red-600 font-bold ' > User Have Been Registerd Today </h1>}

        </div>

        <div className="flex justify-center mt-4">
          <Link to="/scan" className="bg-slate-900 hover:bg-slate-700 text-white font-bold py-2 px-8 rounded">
            Scan Again
          </Link>
          {isInvited && !user.dates[0].attended && (
            <button onClick={handleMakeAttend} className="mx-2 bg-emerald-900 hover:bg-emerald-700 text-white font-bold py-2 px-8 rounded">
              Make Attend
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Result;
