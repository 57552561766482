import React from "react";
import Navbar from "./componants/Navbar";

import Footer  from "./componants/Footer";

import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer/>
    </div>
  );
}
